import React, {useState, useRef, useEffect } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import {
  Button
}  from 'react-bootstrap';
import ModalCameraError from './modalCameraError';

function CameraSection (props) {
  
    const [idealFacingMode, setIdealFacingMode] = useState(FACING_MODES.ENVIRONMENT);
    const [isMaxResolution, setIsMaxResolution] = useState(true);
    const [modal, setModal] = useState(false);
    const [isImageVisible, setIsImageVisible] = useState(true);
    const imageRef = useRef(null);

    const toggleImageVisibility = () => {
      setIsImageVisible(!isImageVisible);
    };
    //const [image, setImage] = useState(null);

    function renderButtons () {
      console.log(idealFacingMode)
      if(idealFacingMode === FACING_MODES.USER){
        setIdealFacingMode(FACING_MODES.ENVIRONMENT);
        setIsMaxResolution(true);
      }
      else{
        setIdealFacingMode(FACING_MODES.USER);
        setIsMaxResolution(false);
      }
        
    }

    useEffect(() => {
      
    },[])

    const style={
        position: 'absolute',
        textAlign: 'center',
        top: 35,
        width: '100%',
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
    }
    const styleImageContainer = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 10,
      pointerEvents: 'none', 
    };
  
    const styleImage = {
      transform: 'rotate(270deg)',
      width: '25%', 
      height: 'auto', 
    };
    const styleImage2 = {
      transform: 'rotate(0deg)',
      width: '35%', 
      height: 'auto', 
    };
    const style1={
        background: 'rgb(255 255 255 / 0.6)',
        color: 'red',
        fontSize: 20,
        fontWeight: 600,
    }
    const style_={
      position: 'absolute',
      textAlign: 'right',
      
      bottom:'44%',
      left:'88%',
      MozUserSelect: "none",
      WebkitUserSelect: "none",
      msUserSelect: "none",
}
  const style_1={
      color: 'red',
      fontSize: 15,
      fontWeight: 600,
  }

    const style2={
      position: 'absolute',
      paddingLeft:25,
      top: '32%',
      MozUserSelect: "none",
      WebkitUserSelect: "none",
      msUserSelect: "none",
      display: 'flex',
      flexDirection: 'column', 
      alignItems: 'center', 
      gap: '10px', 
      marginTop: '10px', 
  }
  // const style3={
  //     textAlign: 'center',
      
  // }

    function handleTakePhoto (dataUri) {
        // Do stuff with the photo...
        //console.log(dataUri);
        props.setImage(dataUri);
        // fetch(dataUri)
        //   .then(res => res.blob())
        //   .then(blob => {
        //     const file = new File([blob], "amrit",{ type: "image/jpeg" })
        //     setImage(file);
        //     console.log(file);
            
        // })
        //console.log('takePhoto');
    }

    const handleClickOutside = (event) => {
      if (imageRef.current && !imageRef.current.contains(event.target) && !event.target.id.includes("inner-circle")) {
        setIsImageVisible(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
  console.log("chassisurtl",props.chassisURL)
      // return () => {
      //   document.removeEventListener('mousedown', handleClickOutside);
      // };
    }, []);
     
    const handleCameraError = (e) =>{
      console.log(e, "Asdsad");
      if(e){
        setModal(true);
      }
      else{
        setModal(false);
      }
    }

    return (
      <>
        <div>
            
            <Camera
                idealFacingMode = {idealFacingMode}
                isFullscreen={true}
                // isMaxResolution = {isMaxResolution}
                // idealResolution = {{width: 1792, height: 828}}
                imageType = {IMAGE_TYPES.JPG}
                isImageMirror={false}
                imageCompression = {0.97}
                onCameraError= {(e) =>{handleCameraError(e)}}
                onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
            />
              {isImageVisible && props.imageId!=='nophoto' && (
          <div style={styleImageContainer} ref={imageRef}>
            <img style={props.singleAImage.id==='chachis-number'?styleImage2:props.imageId?styleImage:styleImage2} src={props.imageId==='nophoto'?null:props.imageId?props.imageId:props.chassisURL} />
          </div>
        )}
            <div style={style}>
              <div style={style1}>{props.title}.</div>
            </div>
            {/* <div style={style_}>
              <div style={style_1}>Click here</div>
            </div> */}
            <div style={style2}>                
                <Button variant="info"style={{fontSize: 15}} onClick={renderButtons}><i class="fa fa-refresh"></i></Button>
                <Button variant="info" style={{ fontSize: 10 }} onClick={toggleImageVisibility}>
                  <i className="fa"></i> Demo
                </Button>
            </div>
        </div>
        <ModalCameraError modal={modal} handleClose={() => setModal(false)} />  
      </>
        
    );
}
 
export default CameraSection;