import React, { useState, useEffect } from 'react';
import {
  useLocation
} from "react-router-dom";
import axios from './axios-details';
import CompleteInspection from './container/Info/completeInspection';
import Chassis from './container/Info/chassisNumber';
import ImageInfo from './container/Info/InspectionInfo';
import ThankYou from './container/thankYou';
import PhotoView from './container/photoView';
import InputView from './container/InputView';
import InfoView from './container/InfoView/InfoView';


const App = (props) =>{
  const [state, setState] = useState(0)

  const [caseId, setCaseId] = useState(null)

  const [vehicle, setVehicle] = useState(null)

  const [details, setDetails] = useState(null)
  const [chassisURL, setChassisURL] = useState(null)
  
  let location = useLocation();

  useEffect(()=>{
      const caseId = location.pathname && location.pathname.split('/');
      if(caseId[1].length > 0){
        setCaseId(caseId[1])
      }
  }, [location])

  const getDataFromInput = (data) =>{
    console.log(data);
    setVehicle(data);
    setState(2)
  }

  const handleStateClick = (state) =>{
    console.log(caseId)
    setState(state)
  }

  const handleStateClickDetails = (det) =>{
    console.log(det)
    setDetails(det)
    setState(5)
  }

  const handleCompleteInspection = () =>{
      console.log(details)
      let data = [
        ...details
      ]
      console.log(JSON.stringify(data))
      const formData = new FormData();

      formData.append('details', JSON.stringify(data));        
      formData.append('vehicle_number', vehicle.vehicle_number);
      formData.append('vehicle_type', vehicle.vehicle_type);
      formData.append('vehicle_color', "red");
      formData.append('make_model_id', vehicle.make_model_id);
      formData.append('fuel_type', vehicle.fuel);
      formData.append('yom', vehicle.yom);
      formData.append('case_id', caseId);

      for (var value of formData.values()) {
          console.log(value); 
      }
      axios.post('/util/upload-claim-json/v2', formData)
      .then(res => {
          console.log(res);
      })
      .catch(err =>{
          console.log(err);
      })
      axios.post('/util/submit-claim-details?cases_id=' + caseId)
      .then(res => {
          console.log(res);
      })
      .catch(err =>{
          console.log(err);
      })
      setState(6)
  }
  const handleChassisImageChange=(i)=>{
    console.log("chassisurtl",i)
    setChassisURL(i);
  }
  const handleHold = (data) =>{
    setVehicle(data)
    console.log("holdd")
    setState(4);
  }
  
  return (
    <>
      { state === 0 ? <InfoView caseId={caseId} handleChange={() => setState(1)} handleHold={ handleHold} /> :  null}
      { state === 1 ? <InputView caseId={caseId} handleChange={getDataFromInput} /> :  null}
      { state === 2 ? <Chassis setChassisImage={(i) => handleChassisImageChange(i)} chassis={vehicle.chassis} handleChange={() => handleStateClick(3)} /> :  null}
      { state === 3 ? <ImageInfo handleChange={() => handleStateClick(4)} /> :  null}
      {/* { state === 4 ? <VideoInfo subType={vehicle.subType} handleChange={() => handleStateClick(5)} /> :  null} */}
      { state === 4 && caseId ? <PhotoView chassisURL={chassisURL} subType={vehicle?vehicle.subType:null} completeInspection={handleStateClickDetails} caseId={caseId} vehicle={vehicle} /> :  null}
      { state === 5 ? <CompleteInspection handleChange={handleCompleteInspection} /> :  null}
      { state === 6 ? <ThankYou /> :  null}
    </>
  );
}

export default App;