import React, { useState, useEffect } from 'react';
import axios from '../axios-details';
import {
    Row,
    Col,
    Button
} from 'react-bootstrap';
import { ArrayToObjectArray, fetchModel, fetchVariant } from '../shared/function';
import NavView from '../component/NavBar';
import Dropdown from '../component/InputField/dropdown';
import Alert from '../component/Alert/common';
import { vehicleType, fuelType } from '../shared/vehicle'
import Header from '../component/header';
import { testVehicle } from '../shared/function';



const InfoView = (props) => {

    const [vehicle, setVehicle] = useState('');
    const [type, setType] = useState(0);

    const [make, setMake] = useState(0);
    const [allMake, setAllMake] = useState([]);

    const [model, setModel] = useState(0);
    const [allModel, setAllModel] = useState([]);
    const [alert, setAlert] = useState(null)

    const [variant, setVariant] = useState(0);
    const [allVariant, setAllVariant] = useState([]);

    const [chassis, setChassis] = useState(0);

    const [fuel, setFuel] = useState(0);
    const [yom, setYom] = useState(0);
    const [allYom, setAllYom] = useState([]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let i = 0; i <= 15; i++) {
            const current = (currentYear - i).toString()
            years.push({ id: current, name: current });
        }
        setAllYom(years)
    }, [])

    const [apiData, setApiData] = useState(null)
    const [error, setError] = useState(null);

    const fetchMakeModel = () => {
        console.log(type)
        axios.get('/master/make-model-variant-with-name')
            .then(res => {
                const { data } = res;
                console.log(data);
                const _make = [];
                data.length > 0 && data.forEach(d => {
                    _make.push(d.make);
                })
                setApiData(data);
                setAllMake(ArrayToObjectArray(_make))
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        setError(null);
        fetchMakeModel();
        setModel(0);
    }, [type])

    useEffect(() => {
        setError(null);
        const _model = fetchModel(apiData, make);
        setAllModel(ArrayToObjectArray(_model));
    }, [make])

    useEffect(() => {
        setError(null);
        const _variant = fetchVariant(apiData, make, model);
        setAllVariant(ArrayToObjectArray(_variant));
    }, [model])

    const getMakeModel = () => {
        //console.log(make, model, variant);
        let id = {};
        apiData.forEach(a => {
            //console.log(a.make, a.model, a.variant, a.id);
            if ((a.make.trim() === make.trim()) && (a.model.trim() === model.trim())) {
                console.log(a);
                // id = a.id;
                id = {
                    id: a.id,
                    chassis: {
                        chassisId: a.chassisId,
                        chassisLocation: a.chassisLocation,
                    },
                    subType: a.subType
                };
            }
        })
        return id;

    }

    const handleVehicleNumber = (e) => {
        setVehicle(testVehicle(e.target.value.toUpperCase()));
    }

    const testVehicle = (text) => {
        let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (text.length > 12) {
            setAlert("Vehicle number should be at most of 12 characters");
            return text.substring(0, 12);;
        }
        else if (format.test(text)) {
            let count = text.length;
            setAlert("Vehicle number should not have any special character")
            return text.substring(0, count - 1);
        }
        else {
            return text;
        }
    }

    const goNext = () => {
        //console.log(make, model, variant);
        const makeModelId = getMakeModel()
        console.log(makeModelId);

        console.log({
            make: make,
            model: model,
            variant: variant,
            fuel: fuel,
            vehicle_number: vehicle,
            vehicle_type: type,
            make_model_id: makeModelId.id,
            chassis: makeModelId.chassis,
            subType: makeModelId.subType,
            yom: parseInt(yom),
        })
        props.handleChange({
            make: make,
            model: model,
            variant: variant,
            fuel: fuel,
            vehicle_number: vehicle,
            vehicle_type: type,
            make_model_id: makeModelId.id,
            chassis: makeModelId.chassis,
            subType: makeModelId.subType,
            yom: parseInt(yom),
        });

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (vehicle && type && make && model && fuel && yom && variant) {
            goNext()
        }
        else {
            setError("Please select the dropdown.")
        }
    }
    const style = {
        fontSize: 20,
        color: '#142e4e;'
    }
    return (
        <>
            <div >
                <NavView />
                <div style={{ width: '100%' }}>
                    <br />
                    {error ? <Alert classes="alert-danger" msg={error} /> : null}

                    <form method="post" onSubmit={(e) => handleSubmit(e)} >
                        <Row style={{ margin: 0 }}>
                            <Col xs={12} className={"text-center pl-4 mb-2"} style={style}>
                                Please enter vehicle details.
                            </Col>
                            <Col xs={12} className="text-center mb-3">
                                <input
                                    required
                                    className="form-control"
                                    placeholder="Enter Vehicle Number"
                                    value={vehicle}
                                    onChange={(e) => handleVehicleNumber(e)}
                                />
                                {/* {
                            alert ?
                            <div style={{color:'red', textAlign:'left', marginBottom:'-7px',lineHeight: 1,padding: '3px 5px'}}>
                                <span >{alert}</span>
                            </div>
                            : null
                        } */}


                            </Col>
                            <Col xs={12} className="text-center mb-3">
                                <Dropdown
                                    required={true}
                                    title={"Select Vehicle Type"}
                                    option={vehicleType}
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    key={1}
                                />
                            </Col>
                            <Col xs={12} className="text-center mb-3">
                                <Dropdown
                                    title={"Select Vehicle Make"}
                                    option={allMake}
                                    value={make}
                                    onChange={(e) => setMake(e.target.value)}
                                    key={2}
                                />
                            </Col>
                            <Col xs={12} className="text-center mb-3">
                                <Dropdown
                                    title={"Select Vehicle Model"}
                                    option={allModel}
                                    value={model}
                                    onChange={(e) => setModel(e.target.value)}
                                    key={3}
                                />
                            </Col>
                            <Col xs={12} className="text-center mb-3">
                                <Dropdown
                                    title={"Select Vehicle Variant"}
                                    option={allVariant}
                                    value={variant}
                                    onChange={(e) => setVariant(e.target.value)}
                                    key={4}
                                />
                            </Col>
                            <Col xs={12} className="text-center mb-3">
                                <Dropdown
                                    title={"Select Vehicle Fuel Type"}
                                    option={fuelType}
                                    value={fuel}
                                    onChange={(e) => setFuel(e.target.value)}
                                    key={5}
                                />
                            </Col>
                            <Col xs={12} className="text-center mb-3">
                                <Dropdown
                                    title={"Select Vehicle Year Of Manufacture"}
                                    option={allYom}
                                    value={yom}
                                    onChange={(e) => setYom(e.target.value)}
                                    key={6}
                                />
                            </Col>

                            <Col xs={12} className="text-center mt-5 mb-5">
                                <Button type="submit" color="info" className="btn-lg" style={{ width: '80%' }}>Start Inspection&nbsp;<i class="fa fa-arrow-right" aria-hidden="true"></i></Button>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        </>

    )
}

export default InfoView;