import React,{ useEffect } from 'react';
import {
    Row, 
    Col, 
}  from 'react-bootstrap';
import NavView from '../component/NavBar';
const ThankYou = (props) =>{

    useEffect(() => {
        {
            alert("Thank you for completing inspection. Please wait on this screen for at least 2 minutes. ")
        }
    },[])

    return (
        <div>
            <NavView />
            <br />
            <Row style={{margin:0}}>
                
                <Col md={12} className="text-justify pl-3 pr-3">
                    Thank You for completing the inspection. We will get back to you for any issues in next 15 minutes. Please keep the browser open for sometime.
                </Col>

                <Col md={12} className="text-center mt-4 pl-3 pr-3" style={{color:'#142e4e', fontSize:20, fontWeight:'bold'}}>
                <a href="tel:+919899876174">+919873377936</a> - Call for support
                </Col>
            </Row>
        </div>
        
    )
}

export default ThankYou;